import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import loadComponent from '../Loadable';

const Link = loadComponent('link');

export default function Copyright() {
  const data = useStaticQuery(graphql`
    query CopyrightQuery {
      wp {
        ...SiteName
      }
    }
  `);
  return (
    <p
      className="copyrightText"
      style={{
        color: `#fff`,
        fontSize: `11px`,
        marginTop: `30px`,
      }}
    >
      Copyright &copy; {new Date().getFullYear()}
      {` `}
      <Link to="/">{data.wp.generalSettings.title}</Link>. All rights reserved.
    </p>
  );
}
